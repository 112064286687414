<!-- 用户编辑弹窗 -->
<template>
  <a-modal :width="680" :visible="visible" :confirm-loading="loading" :title="isUpdate ? '修改现场' : '新建现场'"
    :body-style="{ paddingBottom: '8px' }" @update:visible="updateVisible" @ok="save">
    <a-form ref="form" :model="form" :rules="rules" :labelCol="{ span: 2, offset: 0 }"
      :wrapper-col="{ span: 22, offset: 0 }">
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="活动" name="activitId">
            <a-select v-model:value="form.activityId" show-search placeholder="选择一个活动" showArrow
              :filterOption="filterOption" @change="change" allow-clear>
              <a-select-option v-for="(item, index) in activityList" :key="index" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="名称" name="sceneName">
            <a-input v-model:value="form.sceneName" placeholder="请输入名称" allow-clear />
          </a-form-item>
          <a-form-item label="管理员:" name="managerId">
            <a-select v-model:value="form.managerIdArr" show-search placeholder="选择管理人" showArrow
              :filterOption="filterOption" mode="multiple">
              <a-select-option v-for="(item, index) in managerList" :key="index" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as activityApi from '@/api/activity/activityInfo.js'
import * as activityUserRegApi from '@/api/activity/activityUserReg.js'
import * as sceneApi from '@/api/scene/index'
export default {
  name: 'sceneEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 表单验证规则
      rules: {
        activityId: [
          {
            required: true,
            type: 'string',
            trigger: 'change'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 活动列表
      activityList: [],
      // 管理人员
      managerList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.isUpdate = true
        this.form = this.data
        if (this.form.activityId) {
          this.queryManagerList(this.form.activityId)
        }
        this.form.activityId = this.form.activityId + ''
        this.form.managerIdArr = this.strToArr(this.form.managerId)
        this.form = this.data
      } else {
        this.form = {}
        this.isUpdate = false
        this.managerList = []
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    console.log(this.form)
    this.queryActivityList()
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          if (this.form.managerIdArr && this.form.managerIdArr.length > 0) {
            this.form.managerId = this.arrToStr(this.form.managerIdArr)
          } else {
            this.form.managerId = ''
          }
          sceneApi.save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => { })
    },
    // 数组转字符串方法
    arrToStr(arr) {
      if (arr && arr.length > 0) {
        const str = arr.join(',')
        return str
      }
    },
    // 字符串转数组方法
    strToArr(str) {
      if (str) {
        return str.split(',')
      }
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    /* 选择过滤查询 */
    filterOption(input, option) {
      return (
        option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    queryActivityList(activityId) {
      activityApi.all({ activityId: activityId }).then(res => {
        if (res.code === 0) {
          this.activityList = res.data.map(item => {
            const newItem = {}
            newItem.value = item.activityId + ''
            newItem.label = item.activityName
            return newItem
          })
        }
      }).catch(() => {

      })
    },
    queryManagerList(activityId) {
      activityUserRegApi.all({ activityId: activityId }).then(res => {
        if (res.code === 0) {
          if (res.data && res.data.length === 0) {
            this.managerList = []
            this.form.managerIdArr = []
          } else {
            this.managerList = res.data.map(item => {
              const newItem = {}
              newItem.value = item.userId + ''
              newItem.label = '(' + item.phone + ')' + item.name
              return newItem
            })
          }
        }
      }).catch(() => {

      })
    },
    change(id, optioin) {
      if (!this.form.sceneName) {
        this.form.sceneName = this.activityList[optioin.key].label
      }
      this.queryManagerList(id || -1)
    }
  }
}
</script>

<style scoped>
</style>
