<template>
  <div class="ud-body">
    <a-card :bordered="true">
      <a-form :model="where" :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }">
        <a-row>
          <a-col :lg="8" :md="12" :sm="24" :xs="24">
            <a-form-item label="会场名称:" name="activityName">
              <a-input v-model:value.trim="where.activityName" placeholder="请输入会场名称" allow-clear />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table ref="table" row-key="sceneId" :datasource="datasource" :columns="columns"
        v-model:selection="selection" :scroll="{ x: 'max-content' }">
        <template #toolbar>
          <a-space>
            <a-button type="primary" @click="openEdit()">
              <template #icon>
                <plus-outlined />
              </template>
              <span>新建</span>
            </a-button>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
          </a-space>
        </template>
        <template #modules="{ record }">
          <a-space>
            <a @click="openScreen(record)">大屏设置</a>
            <a-divider type="vertical" />
            <a @click="openLuckGoods(record)">奖品设置</a>
            <a-divider type="vertical" />
            <a @click="openLuckUser(record)">中奖人员</a>
          </a-space>
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openEdit(record)">修改</a>
            <a-divider type="vertical" />
            <a-popconfirm title="确定要删除此项吗？" @confirm="remove(record)">
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <scene-edit v-model:visible="showEdit" :data="current" @done="reload"></scene-edit>
    <a-modal :width="1000" v-model:visible="showLuck" :confirm-loading="loading" :footer="null">
      <luck-index :data="current" @done="reload" :sceneId="current == null ? null : current.sceneId"></luck-index>
    </a-modal>
    <a-modal :width="1000" v-model:visible="showScreen" :confirm-loading="loading" :footer="null">
      <screen-index :data="current" @done="reload" :sceneId="current == null ? null : current.sceneId"></screen-index>
    </a-modal>
    <a-modal :width="1000" v-model:visible="showLuckUser" :confirm-loading="loading" :footer="null">
      <luck-user-index :data="current" @done="reload" :sceneId="current == null ? null : current.sceneId">
      </luck-user-index>
    </a-modal>
  </div>
</template>

<script>
import LuckIndex from './luck'
import SceneEdit from './index-edit'
import ScreenIndex from './screen'
import LuckUserIndex from './luck/user'
import * as sceneApi from '@/api/scene/index'
import { createVNode } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
export default {
  name: 'scene-index',
  components: {
    LuckIndex,
    SceneEdit,
    ScreenIndex,
    LuckUserIndex
  },
  data() {
    return {
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        {
          title: '会场活动名称',
          dataIndex: 'sceneName',
          sorter: true
        },
        {
          title: '功能',
          slots: { customRender: 'modules' }
        },
        {
          title: '操作',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      // 是否显示现场
      showEdit: false,
      // 是否显示抽奖
      showLuck: false,
      // 是否显示大屏
      showScreen: false,
      // 是否显示中奖信息
      showLuckUser: false,
      // 是否显示详情弹窗
      showDetail: false,
      // 当前编辑数据
      current: null,
      detailInfo: null,
      // 编辑表单提交状态
      editLoading: false,
      corpTypeList: []
    }
  },
  methods: {
    datasource(option, callback) {
      sceneApi
        .page({
          ...this.where,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 搜索 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1, where: this.where })
    },
    /*  重置搜索 */
    reset() {
      this.where = {}
      this.reload()
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      sceneApi
        .deleteById(row.sceneId)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.msg)
        })
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的数据吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.sceneId) }
          sceneApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.msg)
            })
        }
      })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    },
    openLuckGoods(row) {
      this.current = row
      this.showLuck = true
    },
    openLuckUser(row) {
      this.current = row
      this.showLuckUser = true
    },
    openScreen(row) {
      this.current = row
      this.showScreen = true
    }
  }
}
</script>

<style></style>
